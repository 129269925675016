.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(25px, auto);
  margin-top: 10px;
  padding: 10px;
  background-color: #ededed;
  border-radius: 4px;
}

.item {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  background-color: #fff;
}

.fullWidth {
  grid-column: 1 / 4;
  font-weight: bold;
}

.header{
  font-size: 18px;
  padding: 20px 0;
}

.subheader {
  padding: 10px 0 10px 15px;
}

.headerMain {
  font-size: 18px;
  padding: 0px 0px 10px 0px;
}

.btnMargin {
  margin: 20px 0;
}